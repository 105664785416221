import Api from '@api';
import { PAGER } from '@constant';

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

/**
 * @description 列表和筛选表单不会展示的组
*/
export const noFilterGroup = ['DemandOrderPrefix', 'OrderPrefix', 'PreadvicePrefix', 'UPIPrefix'];

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [
        { "key": "DSTAMP", "label": "日期", "type": "daterange", "placeholder": " ", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['all'], "tabGroup": "THead", "tabGroupName": '' },
        {
            "key": "CLIENT_ID",
            "label": "客户端ID",
            "type": "magnifier",
            "placeholder": "",
            "checked": false,
            "sheetName": "库存托数统计C_Inv_pal_count",
            "showBlock": ['all'],
            "tabGroup": "THead",
            defaultUpper:true,
            "tabGroupName": ""
        },
        {
            "key": "CLIENT_NAME",
            "label": "客户端名称",
            "type": "input",
            "placeholder": "",
            "checked": false,
            "sheetName": "库存托数统计C_Inv_pal_count",
            "showBlock": ['all'],
            "tabGroup": "THead",
            "tabGroupName": ""
        },
        {
          "key": "CLIENT_SHORT_NAME",
          "label": "客户端简称",
          "type": "input",
          "placeholder": "",
          "checked": false,
          "sheetName": "库存托数统计C_Inv_pal_count",
          "showBlock": ['all'],
          "tabGroup": "THead",
          "tabGroupName": ""
      },
        {
            "key": "SITE_ID",
            "label": "站点",
            "type": "multiselect",
            "placeholder": "站点标识 ",
            "checked": false,
            "sheetName": "Consignment 表说明", "showBlock": ['all'],
            "tabGroup": "THead",
            "tabGroupName": "",
            "valueType":'SINGLE_VALUE',
            "options": async () => {
              let {status, data: {list}, message} = await Api.setSelectSiteList({
                SITE:{},
                ...defaultPaginationInfo
              });
      
              if (Number(status) === 200) {
                return list.map(item=>{
                  return{
                    key: item.SITE_ID,
                    label: item.SITE_ID
                  }
                })
              }
            },
        },

        { "key": "DISTINCT_INV_PAL", "label": "库存托盘数去重", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "INV_PAL", "label": "库存托盘数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "RECEIPT_PAL", "label": "收货托盘数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "DISTINCT_REVERSAL_PAL", "label": "取消收货托盘数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "SHIPPED_PAL", "label": "发货托盘数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "DISTINCT_SHIPPED_PAL", "label": "去重发货托盘数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "DISTINCT_SHIPPED_FULL_PAL", "label": "去重发货整托数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "COUNT_RECEIPT_PAL", "label": "计算收货托盘数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "COUNT_SHIPPED_PAL", "label": "计算发货托盘数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },
        { "key": "COUNT_INV_PAL", "label": "计算库存托盘数", "type": "input", "placeholder": "", "checked": false, "sheetName": "库存托数统计C_Inv_pal_count", "showBlock": ['tableList'], "tabGroup": "", "tabGroupName": "" },

        { 
          "key": "ACTUAL_INV_PAL",
          "label": "实际库存托盘数",
          "type": "input",
          "placeholder": "",
          "checked": false,
          "sheetName": "库存托数统计C_Inv_pal_count",
          "showBlock": ['tableList'],
          "tabGroup": "",
          "tabGroupName": "",
          "editRender": {name: '$input', props: {type: 'number'}}
        },
        { 
          "key": "ACTUAL_RECEIPT_PAL",
          "label": "实际收货托盘数",
          "type": "input",
          "placeholder": "",
          "checked": false,
          "sheetName": "库存托数统计C_Inv_pal_count",
          "showBlock": ['tableList'],
          "tabGroup": "",
          "tabGroupName": "",
          "editRender": {name: '$input', props: {type: 'number'}}
        },
        { 
          "key": "ACTUAL_SHIPPED_PAL",
          "label": "实际发货托盘数",
          "type": "input",
          "placeholder": "",
          "checked": false,
          "sheetName": "库存托数统计C_Inv_pal_count",
          "showBlock": ['tableList'],
          "tabGroup": "",
          "tabGroupName": "",
          "editRender": {name: '$input', props: {type: 'number'}}
        },
        
    ]
  }
}

