import Api from '@api';
import { getFormConfig, noFilterGroup } from "./configs/formConfig";
import { getTableColumn } from './configs/tableConfig';
import { getSearchValueList, keyToConfigList, uniqueArr, isCanShowBlock } from '@utils';
import { TABLE_COLUMN_COMMON } from '@constant';
import habitMixins from '@/mixins/habit.js';
import formTableInitMixins from '@/mixins/formTableInit.js'


export default {
  name: 'invPallet',
  components: {},
  mixins: [habitMixins,formTableInitMixins],
  provide() {
    return {
      getTableList: this.getList
    };
  },
  data: function () {
    const dataTableName = "invPallet";
    const formConfig = getFormConfig(this).filterFormConfig.filter(item => (item.label && isCanShowBlock('searchInfo', item.showBlock)));
    const tableColumn = getTableColumn(this).tableColumn;

    const ALL_SEARCH_ITEM_LIST = JSON.parse(window.sessionStorage.getItem('ALL_SEARCH_ITEM_LIST'))[dataTableName];
    const ALL_TABLE_COLUMNS = JSON.parse(window.sessionStorage.getItem('ALL_TABLE_COLUMNS'))[dataTableName];

    const filterFormConfig = keyToConfigList(ALL_SEARCH_ITEM_LIST, formConfig);
    const tableColumnConfigList = keyToConfigList(ALL_TABLE_COLUMNS, tableColumn, 'tag');

    return {
      formData: {}, //筛选条件
      importData: [], // table 数据
      filterFormConfig,
      conditions: formConfig, // 增加查询条件的值
      tableColumn, // 原本的表格配置
      tableColumnConfigList,
      dataTableName,
      dialog: null,
      injectData: {},
      initFormData:{},
      selectionList: [],
      rangeSelectionList: [],
      searchItemList: ALL_SEARCH_ITEM_LIST,
      tableColumnList: ALL_TABLE_COLUMNS,
    }
  },
  mounted(){
    // this.getList()
  },
  methods: {
    /**
     * @description 初始化数据
     */
    async getList() {
      let CRITERION_LIST = getSearchValueList(this.conditions, this.$refs.filterForm.formData)
      let SHOW_COLUMN_LIST = this.tableColumnConfigList.map(item => item.tag).filter(item => !TABLE_COLUMN_COMMON.includes(item))
      let EXTRA_COLUMN = []
      let params = {
        CRITERION_LIST: CRITERION_LIST,
        SHOW_COLUMN_LIST: uniqueArr([...SHOW_COLUMN_LIST,...EXTRA_COLUMN]),
        PAGE_NUM: this.$refs.configTable.page.currentPage,
        PAGE_SIZE: this.$refs.configTable.page.pageSize
      };
      this.initFormData = this.$refs.filterForm.formData;
      const { status, data, message } = await Api.GetCextensionList(params);
      if(status === 200){
        this.importData = data.list
        this.$refs.configTable.page.total = Number(data.total)
      }
    },
    /**
     * @description 表单编辑时候的跳转
    */
    clientEdit(params, type) {
      // this.dialog = type;
      // this.injectData = {...params};
      console.log(params, type);
    },
    /**
     * @description 清除表单和父子间的数据
    */
    dialogClosed(){
      this.dialog = null;
      this.injectData = {};
    },
   /**
    * @description 导出
   */
   async exportData () {
      // exportByFront('库存托盘数查询', this.tableColumnConfigList, this.importData)
      let res = await Api.ExportCInvPalCount();
      const data = res
      const url = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '库存托盘数查询.xls')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    /**
     * @desc 保存更改的数据
     * @param ACTUAL_INV_PAL 实际库存托盘数
     * @param ACTUAL_RECEIPT_PAL 实际收货托盘数
     * @param ACTUAL_SHIPPED_PAL 实际发货托盘数
    */
    async saveEvent() {
      const { updateRecords } = this.$refs.configTable.$refs.vxeTable.getRecordset();
      
      let validate = true;

      updateRecords.forEach((item, index) => {
        if (item.ACTUAL_INV_PAL < 0 || item.ACTUAL_RECEIPT_PAL < 0 || item.ACTUAL_SHIPPED_PAL < 0) {
            validate = false
            return this.$message.warning('提示: 修改的内容不能为负数,请检查修改!')
        }
      })

      if (validate) {
        const {status, message} = await Api.UpdateInvPalCount(updateRecords)
        if (status === 200) {
          this.$message.success(message);
          this.getList()
        }
       
      } else {
        console.log('break')
      }
    }
  }
}